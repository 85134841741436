<template>
  <OProductCarouselOffer
    :title="products?.title"
    :products="products?.productsList"
    :loading="loading"
    with-background
  />
</template>
<script lang="ts" setup>
import { ref, defineComponent } from 'vue'
import { useProductCollections } from '@/stores/product-collection'

import OProductCarouselOffer from '@/components/organisms/CarouselOffer/OProductCarouselOffer.vue'

defineComponent({ name: 'HomeDelicaciesCollections' })

const loading = ref(process.client)
const products = ref()

await useHydrationData('home-product-collections', () => useProductCollections().getHomeCollections(), {
  then: ({ data }) => {
    products.value = data.value?.[2]
    loading.value = false
  },
  catch: () => undefined,
  lazy: true
})
</script>
